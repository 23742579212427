<template>
  <div id="fb-ana" :class="{ topPadding: pageInfo.page !== 'ana' }" ref="ana">
    <!-- :style="
      this.$store.state.user.userinfo.memberStatus === 1 ? '' : 'overflow-y: hidden'
    " -->
    <fb-header slot="header" :title="title" fixed v-if="pageInfo.page !== 'ana'">
      <fb-button slot="left" icon="back" size="small" type="primary" @click="clickBack"> 返回 </fb-button>
      <fb-button slot="right" icon="menu" size="small" type="primary"> </fb-button>
    </fb-header>

    <!-- <div class="container"
         ref="scrollDocument"
         v-show="pageInfo.page==='ana'">
      <analysis ref="analysis"
                :step.sync="step"
                :pageInfo="pageInfo"
                :scrollDocument="$refs.scrollDocument" />
    </div>
    <div class="container"
         ref="detailContainer"
         v-if="pageInfo.page==='detail'||pageInfo.page==='detailInTopCase'">
      <detail :pageInfo="pageInfo"
              :title.sync="title"
              :scrollDocument="$refs.detailContainer" />
    </div>
    <div class="container"
         v-if="pageInfo.page==='topCase'||pageInfo.page==='detailInTopCase'"
         v-show="pageInfo.page!=='detailInTopCase'">
      <top-case :pageInfo="pageInfo" />
    </div> -->
    <!-- <van-pull-refresh v-model="isLoading"
                      pulling-text="下拉即可重新输入分析内容"
                      loosing-text="释放即可重新输入分析内容"
                      @refresh="onRefresh"> -->
    <van-list v-model="loading" :finished="finished" :finished-text="appeal_flag ? '' : finishedText" @load="onLoad">
      <div class="container" ref="scrollDocument" v-show="pageInfo.page === 'ana'">
        <analysis
          ref="analysis"
          :step.sync="step"
          :pageInfo="pageInfo"
          :scrollDocument="$refs.ana"
          @resetFinished="resetFinished"
          @resetLoading="resetLoading"
          @resetFinishedText="resetFinishedText"
        />
      </div>
      <div class="container" v-if="pageInfo.page === 'topCase' || pageInfo.page === 'detailInTopCase'" v-show="pageInfo.page !== 'detailInTopCase'">
        <top-case :pageInfo="pageInfo" @resetFinished="resetFinished" @resetLoading="resetLoading" />
      </div>
    </van-list>
    <!-- </van-pull-refresh> -->
    <div class="container" ref="detailContainer" v-if="pageInfo.page === 'detail' || pageInfo.page === 'detailInTopCase'">
      <detail :pageInfo="pageInfo" :title.sync="title" :scrollDocument="$refs.ana" @resetFinished="resetFinished" @resetLoading="resetLoading" @clickBack="clickBack" />
    </div>
  </div>
</template>
<script>
import analysis from './pages/analysis'
import detail from './pages/detail'
import topCase from './pages/topCase'
import { caseBase } from '~api-config'
export default {
  name: 'Ana',
  components: {
    analysis,
    detail,
    topCase,
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      finished: false,
      finishedText: 'loading',
      title: '分析',
      step: 0,
      pageInfo: {
        page: 'ana',
        id: null,
        token: null,
      },
      startPos: {},
      endPos: {},
      appeal_flag: false,
      memberNum: -1,
    }
  },
  methods: {
    // 点击返回
    clickBack() {
      if (this.pageInfo.page === 'ana') {
        // 在分析页中
        if (this.step >= 2) {
          this.step--
        } else {
          // 在最开始页，调用原生方法返回工具页
          if (this.$router.length >= 1) {
            this.$router.back()
          } else {
            try {
              const u = navigator.userAgent
              if (u === 'fb-Android-Webview') {
                // 安卓环境
                // eslint-disable-next-line
                AND2JS.back()
              } else if (u === 'fb-iOS-Webview') {
                // ios环境
                // eslint-disable-next-line
                window.webkit.messageHandlers.backToTool.postMessage({})
              } else if (u === 'fb-flutter-Webview') {
                // eslint-disable-next-line
                window.backToTool.postMessage('{}')
              } else {
                this.$toast('非原生环境中已经在最前页了。')
              }
            } catch (err) {
              console.log(err, 'backToTool.postMessage')
            }
          }
        }
      } else if (this.pageInfo.page === 'detailInTopCase') {
        // 如果是从100案列中到详情页面的时候
        this.title = '分析'
        this.pageInfo.id = null
        this.pageInfo.page = 'topCase'
        this.finished = false
      } else {
        // 如果不在分析页，即在100top和原文中
        this.title = '分析'
        this.pageInfo.id = null
        this.pageInfo.token = null
        this.pageInfo.page = 'ana'
        this.finished = false
      }
    },
    async getInfo() {
      // 挂在状态
      this.loading = true
      this.finishedText = ' '
      if (this.$route.params.caseId) {
        const res = await this.$axios.get(`${caseBase}/new-design/caseUserDocument/queryByCaseId/${this.$route.params.caseId}`)
        this.loading = false
        // console.log(res)
        if (!res.data.data.caseUserDocument.length) {
          this.$notify({
            message: '未获取到诉讼请求和事实理由,请填写后再进行分析。',
            duration: 1000,
          })
          // 取消挂载状态
        } else {
          const resData = JSON.parse(res.data.data.caseUserDocument[0].documentData)[0]
          const keyWordValue = []
          for (const key in resData) {
            if (key.startsWith('shiShiLiYou') || key.startsWith('suSongQingQiu')) {
              keyWordValue.push(resData[key])
            }
          }
          this.$refs.analysis.analysisData.textData = keyWordValue.join('x')
          await this.$refs.analysis.texAanalysis()
        }
      }
      this.loading = false
    },
    onRefresh() {
      this.$refs.analysis.showHeader()
      setTimeout(() => {
        this.isLoading = false
      }, 20)
    },
    async onLoad() {
      // console.log('无限加载')
      if (this.step === 0) {
        this.finishedText = ''
        this.loading = false
        this.finished = true
      } else if (this.pageInfo.page === 'ana') {
        // 在分析页中
        this.finishedText = ''
        if (this.step >= 2) {
          if (!this.$refs.analysis.$refs.two.currentTab.selectedArray.length) {
            return
          }
          const res = await this.$refs.analysis.$refs.two.currentTab.viewMore()
          if (!res) {
            this.finished = true
          }
          this.loading = false
        } else {
          const res = await this.$refs.analysis.$refs.one.viewMore()
          if (!res) {
            this.finished = true
          }
          this.loading = false
        }
      } else if (this.pageInfo.page === 'detail' || this.pageInfo.page === 'detailInTopCase') {
        this.finishedText = ''
        this.finished = true
        this.loading = false
      } else if (this.pageInfo.page === 'topCase') {
        this.finished = true
        this.loading = false
      } else {
        this.finished = true
        this.loading = false
      }
    },
    // 传了一个false
    resetFinished(value) {
      this.finished = value
      if (document.querySelector("div[data-fx='fx']")) {
        document.querySelector("div[data-fx='fx']").style.visibility = 'visible'
      }
    },
    resetLoading(value) {
      // console.log('value', value)
      this.loading = value
    },
    resetFinishedText(value, type) {
      if (type.indexOf('appeal') > -1) {
        this.appeal_flag = true
      } else {
        this.appeal_flag = false
      }
      this.finishedText = value
    },
    // 查询非会员次数
    // getNoMemberNum() {
    //   this.$axios
    //     .get(`${this.$base}/management/user/residue/search`)
    //     .then((res) => {
    //       if (res.data.code === 200 || res.data.code === '200') {
    //         this.memberNum = res.data.data.restNum
    //       } else {
    //         this.memberNum = -1
    //       }
    //     })
    //     .catch(() => {
    //       this.memberNum = -1
    //     })
    // },
  },
  mounted() {
    if (this.$route.query[0]) {
      console.log('有数据')
      // 有参数 修改数据

      this.step = 2
    }
  },
  created() {
    // this.getNoMemberNum()
  },
}
</script>
<style scoped lang="stylus">
#fb-ana
  height 100vh
  overflow-y auto
  // overflow-x hidden
#fb-ana.topPadding
  padding-top 1.173333rem
</style>
