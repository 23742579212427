var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "ana",
      class: { topPadding: _vm.pageInfo.page !== "ana" },
      attrs: { id: "fb-ana" },
    },
    [
      _vm.pageInfo.page !== "ana"
        ? _c(
            "fb-header",
            {
              attrs: { slot: "header", title: _vm.title, fixed: "" },
              slot: "header",
            },
            [
              _c(
                "fb-button",
                {
                  attrs: {
                    slot: "left",
                    icon: "back",
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.clickBack },
                  slot: "left",
                },
                [_vm._v(" 返回 ")]
              ),
              _c("fb-button", {
                attrs: {
                  slot: "right",
                  icon: "menu",
                  size: "small",
                  type: "primary",
                },
                slot: "right",
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-list",
        {
          attrs: {
            finished: _vm.finished,
            "finished-text": _vm.appeal_flag ? "" : _vm.finishedText,
          },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pageInfo.page === "ana",
                  expression: "pageInfo.page === 'ana'",
                },
              ],
              ref: "scrollDocument",
              staticClass: "container",
            },
            [
              _c("analysis", {
                ref: "analysis",
                attrs: {
                  step: _vm.step,
                  pageInfo: _vm.pageInfo,
                  scrollDocument: _vm.$refs.ana,
                },
                on: {
                  "update:step": function ($event) {
                    _vm.step = $event
                  },
                  resetFinished: _vm.resetFinished,
                  resetLoading: _vm.resetLoading,
                  resetFinishedText: _vm.resetFinishedText,
                },
              }),
            ],
            1
          ),
          _vm.pageInfo.page === "topCase" ||
          _vm.pageInfo.page === "detailInTopCase"
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pageInfo.page !== "detailInTopCase",
                      expression: "pageInfo.page !== 'detailInTopCase'",
                    },
                  ],
                  staticClass: "container",
                },
                [
                  _c("top-case", {
                    attrs: { pageInfo: _vm.pageInfo },
                    on: {
                      resetFinished: _vm.resetFinished,
                      resetLoading: _vm.resetLoading,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.pageInfo.page === "detail" || _vm.pageInfo.page === "detailInTopCase"
        ? _c(
            "div",
            { ref: "detailContainer", staticClass: "container" },
            [
              _c("detail", {
                attrs: {
                  pageInfo: _vm.pageInfo,
                  title: _vm.title,
                  scrollDocument: _vm.$refs.ana,
                },
                on: {
                  "update:title": function ($event) {
                    _vm.title = $event
                  },
                  resetFinished: _vm.resetFinished,
                  resetLoading: _vm.resetLoading,
                  clickBack: _vm.clickBack,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }